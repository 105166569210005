import { getPluralEn } from "./plural/en";

export const enTranslations = {
  "common.advanced-settings": "Advanced settings",
  "common.edit": "Edit",
  "common.delete": "Delete",
  "common.dates": "Dates",
  "common.close": "Close",
  "common.clear": "Clear",
  "common.save": "Save",
  "common.hide": "Hide",
  "common.show": "Show",
  "common.objects-plural": (number: number) =>
    getPluralEn({ number, key: "objects" }),
  "geometry.input-at-map": "Set geometry on the map",
  "data-extraction.add-data": "Add data",
  "data-extraction.title": "Data export",
  "data-extraction.get-result": "Run",
  "data-extraction.add-dates-to-result": "Add dates to result",
  "data-extraction.choose-params": "Add params",
  "data-extraction.choose-points": "Add points",
  "data-extraction.copy-config": "Copy config",
  "data-extraction.import-config": "Import config",
  "random-forest.title": "Random forest",
  "random-forest.choose-training-points": "Training points",
  "random-forest.choose-all-training-points": "Set training points",
  "random-forest.choose-presence": "Presence points",
  "random-forest.choose-output-mode": "Classification mode",
  "random-forest.choose-absence": "Absence points",
  "random-forest.choose-region": "Add  region of interest",
  "random-forest.all-training-points": "All points",
  "random-forest.separate-training-points": "Separate points",
  "random-forest.post-process": "Post process images",
  "random-forest.buffersPerAreaPoint":
    "Buffers around discrete classificated images",
  "random-forest.classificationSplits": "Split classificated images by percent",
  "script-input.scale": "scale",
  "script-input.buffer": "buffer",
  "script-input.filename": "filename",
  "script-input.bands": "bands",
  "population.title": "Estimate population",
  "population.choose-type": "Estimation type",
  "population.random-generation": "Random points generation",
  "population.distance": "Distance",
  "population.density": "Census on plots",
  "population.observed-areas": "Observed areas",
  "population.presence-points": "Presence points",
  "population.presence-area": "Presence area",
  "population.cross-validation": "Cross validation",
  "population.seed": "Random seed",
  "population.use-random-forest": "Use random forest",
  "population.distance-file": "File for distance",
  "population.distance-function": "Key function",
  "population.density-file": "Plots file",
  "population.distance-total-area": "Total area",
  "random-forest.validation": "Validation",
  "random-forest.validation.split": "Split percent",
  "random-forest.validation.seed": "Random seed",
  "random-forest.validation.split-points": "Split training points",
  "random-forest.choose-validation-points": "Validation points",
  "random-forest.validation.external": "External points",
  "random-forest.validation.cross_validation": "Cross validation",
  "random-forest.validation.render_mean": "Render mean",
  "random-forest.validation.render_best": "Render best",
  "random-forest.validation.use-by-default": "Use by default",
  "random-forest.validation.best": "Best",
  "random-forest.validation.mean": "Mean",
  "survival.title": "Survival",
  "survival.file": "File for MARK nest survival",
  "survival.nocc": "Number of occasions",
  "maxent.title": "Maxent",
  "maxent.background_points": "Background points",
  "maxent.generate_background_points": "Generate background points",
  "maxent.background_points_count": "Background points count",
  "migrations.title": "Migrations",
};
